import styled from "react-emotion";

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 1.5rem;

    @media screen and (min-width: 768px) {
        padding: 0 3em;
    }
    @media screen and (min-width: 1024px) {
        max-width: 80em;
        width: 90%;
    }
`;

export default Container;
