import React from "react";

import { LogoNethouse } from "../assets";
import { StyledIcon } from "@common/components";
import LogoWrap from "./LogoWrap";
import LogoText from "./LogoText";

const Logo = () => {
    return (
        <LogoWrap>
            <StyledIcon width="140" height="auto">
                <LogoNethouse />
            </StyledIcon>
            <LogoText>Домены</LogoText>
        </LogoWrap>
    );
};

export default Logo;
