import styled from "react-emotion";

const LogoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -0.625em;
`;

export default LogoWrap;
