import styled from "react-emotion";


const TopBar = styled.header`
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1600 - 320)));
    font-family: Inter, arial, sans-serif;
    width: 100%;
    color: #02508B;
    height: 80px;
    display: flex;
    align-items: center;
`;

export default TopBar;
