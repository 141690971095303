import React from "react";

import TopBar from "./TopBar";
import Container from "./Container";
import TopbarFlex from "./TopbarFlex";
import Logo from "./Logo";
import Tagline from "./Tagline";

export default class TopbarBlock extends React.Component {

    state = {
        open: false
    };

    OpenMenu = () => {
        this.setState(({open}) => {
            return {
                open: !open
            };
        });
    };

    ButtonData = [
        {
            "id": "1",
            "default": false,
            "buttonText": "Войти",
            "buttonLink": "//domains.nethouse.ru/login"
        },
        {
            "id": "2",
            "default": true,
            "buttonText": "Регистрация",
            "buttonLink": "//domains.nethouse.ru/registration"
        }

    ];

    MenuData = [
        {
            "id": "1",
            "linkText": "Nethouse.ru",
            "linkHref": "//nethouse.ru/"
        },
        {
            "id": "2",
            "linkText": "NETHOUSE.САЙТЫ",
            "linkHref": "//nethouse.ru/capabilities"
        },
        {
            "id": "3",
            "linkText": "NETHOUSE.ДОМЕНЫ",
            "linkHref": "https://domains.nethouse.ru/"
        },
        {
            "id": "4",
            "linkText": "NETHOUSE.ПРОДВИЖЕНИЕ",
            "linkHref": "//pro.nethouse.ru/"
        },
        {
            "id": "5",
            "linkText": "NETHOUSE.СОБЫТИЯ",
            "linkHref": "//events.nethouse.ru/"
        },
        {
            "id": "6",
            "linkText": "Nethouse.Агенты",
            "linkHref": "//agent.nethouse.ru/"
        },
        {
            "id": "7",
            "linkText": "Nethouse.Академия",
            "linkHref": "//academy.nethouse.ru"
        },
        {
            "id": "8",
            "linkText": "Nethouse.Образование",
            "linkHref": "//edu.nethouse.ru/"
        },
        {
            "id": "9",
            "linkText": "О нас",
            "linkHref": "//nethouse.ru/contacts/aboutcompany"
        },
        {
            "id": "10",
            "linkText": "Новости",
            "linkHref": "//nethouse.ru/blog/tag/%D1%81%D0%BE%D0%B1%D1%8B%D1%82%D0%B8%D1%8F/1"
        },
        {
            "id": "11",
            "linkText": "Помощь",
            "linkHref": "//domains.nethouse.ru/help/"
        },
        {
            "id": "12",
            "linkText": "Форум",
            "linkHref": "//forum.nethouse.ru/"
        }
    ];

    render() {
        return (
            <TopBar>
                <Container>
                    <TopbarFlex>
                        <Logo />
                        <Tagline>Аккредитованный регистратор с 2008 года</Tagline>
                    </TopbarFlex>
                </Container>
            </TopBar>
        );
    }
}
