import styled from "react-emotion";

const Tagline = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    margin: 0 0 -0.4em 15%;
    display: none;

    @media screen and (min-width: 1024px) {
        display: block;
    }
`;

export default Tagline;
