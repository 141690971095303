import styled from "react-emotion";

const LogoText = styled.span`
    font-weight: 600;
    font-size: 1.5em;
    line-height: 1;
    letter-spacing: -0.013em;
    margin-bottom: -0.1em;
    margin-left: 0.6666em;
    padding-left: 0.6666em;
    border-left: 2px solid #D5DAE6;
    display: none;

    @media screen and (min-width: 768px){
        display: block;
    }
`;

export default LogoText;
